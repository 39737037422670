/**
 * Icons
 */

.icon-9-2,
.icon-9-5,
.icon-3-2,
.icon-5-1,
.icon-34-2,
.icon-2-2,
.icon-9-2,
.icon-7-2,
.icon-4-4,
.icon-44-2,
.icon-30-3,
.icon-45-1,
.icon-26-1,
.icon-36-4,
.icon-1-2,
.icon-15-2,
.icon-18-5,
.icon-29-2,
.icon-52-2,
.icon-22-0,
.icon-28-4,
.icon-28-7,
.icon-17-3,
.icon-40-4,
.icon-62-4,
.icon-24-1,
.icon-11-2,
.icon-46-2,
.icon-64-2,
.icon-65-1,
.st2 {
    fill: var(--tenant-primary) !important;
}

.icon-64-4,
.icon-11-4,
.icon-24-2,
.icon-62-4,
.icon-62-3,
.icon-62-1,
.icon-40-3,
.icon-3-3,
.icon-5-5,
.icon-2-0,
.icon-9-5,
.icon-34-4,
.icon-7-4,
.icon-4-0,
.icon-44-4,
.icon-30-4,
.icon-26-3,
.icon-36-0,
.icon-1-4,
.icon-15-4,
.icon-18-2,
.icon-22-5,
.icon-28-0,
.icon-28-4,
.icon-17-2,
.st1 {
    stroke: var(--tenant-primary) !important;
}

/**
 * Logo
 */
.conversion-header .navbar-expand .navbar-brand,
.conversion-header .navbar-expand .navbar-brand > img {
    min-width: 12.5rem !important;
    max-width: 12.5rem !important;
    max-height: 3rem !important;
}

/**
 * Background
 */
.tenant-btn.btn-success:not(label.btn),
.tenant-btn.btn-success:not(label.btn):not([href]):not(:disabled):not(
        .disabled
    ),
.tenant-btn.btn-success:not([href]),
.tenant-btn.btn-success:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: var(--tenant-primary);
    border-color: var(--tenant-primary);
}
.green-header .header-section,
.badge-primary,
.background-green {
    background-color: var(--tenant-primary);
}
.bg-light {
    background-color: $tenant_background_light !important;
}

.bg-primary {
    background-color: var(--tenant-primary) !important;
}

.btn-primary:not(label.btn),
.btn-primary:not(label.btn):not([href]):not(:disabled):not(.disabled),
.btn-primary:not([href]),
.btn-primary:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: var(--tenant-primary);
}
.custom-control-input:checked ~ .custom-control-label::before {
    background-color: var(--tenant-primary);
}
.custom-checkbox
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: var(--tenant-primary) _50;
}

.step-icon-soft-primary {
    background-color: $tenant_light;
}

.progress-bar {
    background-color: var(--tenant-primary);
}

.page-item.active .page-link {
    background-color: var(--tenant-primary);
}

/**
 * Borders
 */
.btn-primary:not(label.btn),
.btn-primary:not(label.btn):not([href]):not(:disabled):not(.disabled),
.btn-primary:not([href]),
.btn-primary:not([href]):not([href]):not(:disabled):not(.disabled) {
    border-color: var(--tenant-primary);
}
.custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--tenant-primary);
    color: var(--tenant-primary) !important;
}
.nav-classic .nav-link.active {
    border-bottom-color: var(--tenant-primary);
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
    border-top: 0.1875rem solid $tenant_light;
}
.card-frame-highlighted,
.card-frame:hover {
    border-color: var(--tenant-primary) _70;
}
.page-item.active .page-link {
    border-color: var(--tenant-primary);
}

/**
 * Buttons
 */
.btn-outline-primary {
    color: var(--tenant-primary);
    border-color: var(--tenant-primary);
}

/**
 * Text
 */
.card-btn-toggle {
    color: var(--tenant-primary) !important;
}
.text-success {
    color: var(--tenant-primary) !important;
}
.text-success-green {
    color: #00c9a7 !important;
}
.nav-phonenumber,
.btn-white,
a,
.step-icon-soft-primary,
.nav:not(.nav-pills) .nav-item.active .nav-link,
.nav:not(.nav-pills) .nav-link.active {
    color: var(--tenant-primary);
}
.text-primary {
    color: var(--tenant-primary) !important;
}
.text-primary-70 {
    color: var(--tenant-primary) _50;
}
.btn-link {
    color: var(--tenant-primary);
}

footer {
    .background-green {
        background: $tenant_light;
        .text-white {
            color: var(--tenant-primary) !important;
        }
    }
    .nav-white .nav-link {
        color: var(--tenant-primary) !important;
    }
    .text-white-70 {
        color: var(--tenant-primary) _70;
    }
    .footer-usp-icon i {
        color: var(--tenant-primary);
    }
}

.btn-soft-primary {
    color: var(--tenant-primary);
    background-color: var(--tenant-primary-10);
    &:hover {
        background-color: var(--tenant-primary);
    }
}
.footer .nav-link:hover,
.nav .nav-link:not(.active):hover {
    color: var(--tenant-hover) !important;
}

.btn-soft-success {
    color: var(--tenant-primary);
    background-color: var(--tenant-primary-30);
    &:hover {
        color: white;
        background-color: var(--tenant-primary);
    }
}

.custom-select:focus,
.form-control:focus {
    border-color: var(--tenant-primary-30);
    box-shadow: 0 0 10px var(--tenant-primary-10);
}

.link-icon {
    color: var(--tenant-primary);
    background-color: var(--tenant-primary-10);
}

.icon-color-0 {
    color: var(--tenant-primary-10);
}

.icon-color-50 {
    color: var(--tenant-primary-50);
}

.icon-color-100 {
    color: var(--tenant-primary);
}

.border-dark {
    border-color: #babbbb;
}

.shadow-primary-lg {
    box-shadow: 0px 0px 50px var(--tenant-primary-30) !important;
}
