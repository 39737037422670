$tenant_primary: #791e75;
$tenant_primary_70: rgba(121, 30, 117, 0.7);
$tenant_primary_50: rgba(121, 30, 117, 0.5);
$tenant_light: #fbe7f0;
$tenant_background_light: #f5e8ee4a;

@import "tenant";

/**
 * Into Ears
 *
 */
.navbar-brand,
.navbar-brand > img {
    width: 8.5rem;
}

.top-bar .nav .nav-link:first-child {
    padding-left: 0;
}

/**
 * Logo
 */
.conversion-header .navbar-expand .navbar-brand,
.conversion-header .navbar-expand .navbar-brand > img {
    min-width: 10.5rem !important;
    max-width: 10.5rem !important;
    max-height: 5rem !important;
}

:root {
    --tenant-primary: #791e75;
    --tenant-hover: #5d1759;
    --tenant-primary-light: #963d91;
    --tenant-primary-70: rgba(121, 30, 117, 0.7);
    --tenant-primary-50: rgba(121, 30, 117, 0.5);
    --tenant-primary-30: rgba(121, 30, 117, 0.3);
    --tenant-primary-10: rgba(121, 30, 117, 0.1);
    --tenant-light: #fbe7f0;
    --tenant-background-light: #f5e8ee4a;
}
